<template>
  <div>
    <el-row :gutter="10"
            style="padding-bottom:10px">
      <el-col :lg="12"
              :md="12"
              :sm="12"
              :xl="12">
        <el-button type="primary"
                   @click="add">新增</el-button>
      </el-col>
    </el-row>
    <Table ref="TablesRef"
           :tableData="tableData" />
    <pagination ref="pagination"
                :get-data="initData"
                :now-page.sync="page"
                :now-size.sync="size"
                :total="totalElements" />
    <Form ref="formRef" />
  </div>
</template>

<script>
import { getList } from '@/api/examType.js'
import Form from './components/index.vue'
import { saveApi } from '@/api/examType.js'

export default {
  data () {
    return {
      page: 1,
      size: 10,
      totalElements: 0,
      tableData: [],
      pageName: 'edu_exam_type',
      tableColumns: [
        { prop: 'paper_test_type_id', align: 'center', label: '类型ID', },
        { prop: 'type_name', align: 'center', label: '类型名称', },
        { prop: 'sort', align: 'center', label: '排序', },
        { prop: 'status', align: 'center', label: '状态', isStatus: true },
        {
          prop: 'd', align: 'center', label: '操作', width: '200', type: 'text', btns: [
            { name: '修改', class: 'text_btn_primary', event: 'toInfo' },
            { name: '删除', class: 'text_btn_danger', event: 'del' },
          ]
        },
      ]
    }
  },
  components: {
    Form
  },
  mounted () {
    this.setTablesColums()
  },
  methods: {
    getClass (prop, value) {
      return value ? 'pass' : 'notpass'
    },
    getValue (prop, value, row) {
      return value ? '开启' : '禁用'
    },
    setTablesColums () {
      this.$refs.TablesRef.pageName = this.pageName
      this.$refs.TablesRef.setColumns(this.tableColumns)
    },
    async initData (page, limit) {
      let params = {
        page, limit,
        show_page: 1
      }
      const { data } = await getList(params)
      this.tableData = data.list
      this.totalElements = data.count
    },
    del (row) {
      this.$confirm("确定删除？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(async () => {
        let form = JSON.parse(JSON.stringify(row))
        form.is_del = 1
        await saveApi(form)
        this.$notify({
          title: '提示',
          message: '成功!',
          type: 'success'
        });
        this.search()
      })
    },
    toInfo (row) {
      let form = JSON.parse(JSON.stringify(row))
      this.$refs.formRef.text = '修改'
      this.$refs.formRef.form = form
      this.$refs.formRef.dialogVisible = true
    },
    search () {
      this.$refs.pagination.toFirstPage()
    },
    add () {
      let form = {
        type_name: '',
        sort: 0,
        status: 1
      }
      this.$refs.formRef.text = '新增'
      this.$refs.formRef.form = form
      this.$refs.formRef.dialogVisible = true
    }
  }
}
</script>

<style lang="scss" scoped>
</style>
